import { setToken } from '../utils/cookie'
export default {
  SET_TOKEN(state, token) {
    state.token = token
    setToken(token)
  },
  SETUSERINFO(state, data) {
    state.userInfo = data
  },
  SET_USERDATA(state, data) {
    state.userData = data
  },
}
