import request from '@/utils/request'
// 登录
export function callcenterLogin(data) {
  return request({
    url: '/callcenter/callCenterUser/callCenterLogin',
    method: 'post',
    data,
  })
}
// 子账号列表
export function childAccountTable(data) {
  return request({
    url: '/callcenter/callCenterUser/selectCallCenterUsersPage',
    method: 'post',
    data,
  })
}
// 根据id删除子账号
export function delChildAccount(id) {
  return request({
    url: 'callcenter/callCenterUser/deleteCallCenterUser',
    method: 'delete',
    params: { id },
  })
}
// 编辑子账号
export function editChildAccount(data) {
  return request({
    url: '/callcenter/callCenterUser/updateCallCenterUser',
    method: 'post',
    data,
  })
}
// 添加子账号
export function addChildAccount(data) {
  return request({
    url: '/callcenter/callCenterUser/addCallCenterUser',
    method: 'post',
    data,
  })
}
// 根据id 禁用子账号
export function limitChildAccount(params) {
  return request({
    url: '/callcenter/callCenterUser/IsDisableAndEnabled',
    method: 'put',
    params,
  })
}
