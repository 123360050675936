import { callcenterLogin } from '@/api/index'
import { setTheLocalStore } from '@/utils/setTheLocalStore'
import { message } from 'ant-design-vue'

export default {
  userLogin(store, data) {
    return new Promise((resolve, reject) => {
      callcenterLogin(data)
        .then((res) => {
          this.commit('SET_TOKEN', res.data.token)
          setTheLocalStore.setLocalStore(
            'token',
            res.data.token,
            'localStorage'
          )
          setTheLocalStore.setLocalStore('userData', res.data, 'localStorage')
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  resetToken() {
    return new Promise((resolve) => {
      this.commit('SETUSERINFO', null)
      this.commit('SET_TOKEN', '')
      setTheLocalStore.clearLocalStore('all')
      resolve()
    })
  },
}
