import request from '@/utils/request';
import Qs from "qs";

export function getUserInfo(){
    return request({
        url:"/callcenter/callCenterUser/getCallCenterUser",
        method:"post"
    })
}

export function editUserInfo(data){
    return request({
        url:"/callcenter/callCenterUser/updateUserPwd",
        method:"post",
        data:Qs.stringify(data)
    })
}