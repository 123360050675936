import { createRouter, createWebHistory } from 'vue-router'
import _import from '@/utils/import'
import store from '@/store'
import { message } from 'ant-design-vue'
const Layout = _import('layouts')
const HeaderLayout = _import('layouts/HeaderLayout')
const AccountLayout = _import('userInfo/layout')

import { getUserInfo } from '@/api/user'

export const routes = [
    {
        path: '/',
        redirect: '/home',
        component: Layout,
        children: [
            {
                path: '/home',
                component: _import('home'),
            },
        ],
    },
    {
        path: '/login',
        component: _import('login'),
    },
    //账号管理
    {
        path: '/accounts',
        name: 'accounts',
        component: Layout,
        meta: {
            title: 'Account Management',
            icon: require('../assets/account.png'),
            verify: ['role'],
        },
        children: [
            {
                path: 'subaccount',
                meta: { title: 'Sub-account' },
                component: _import('accounts/index'),
            },
        ],
    },
    //标签管理
    {
        path: '/tags',
        name: 'tags',
        component: Layout,
        meta: {
            title: 'Tags Management',
            icon: require('../assets/tags.png'),
            verify: ['role'],
        },
        children: [
            {
                path: 'tags-management',
                meta: { title: 'All Tags' },
                component: _import('tags/index'),
            },
        ],
    },
    //客户管理
    {
        path: '/customer',
        name: 'customer',
        component: Layout,
        meta: {
            title: 'Client Management',
            icon: require('../assets/customer-1.png'),
        },
        children: [
            {
                path: 'customer_list',
                meta: { title: 'All Client' },
                component: _import('customer/index'),
            },
            {
                path: 'performance',
                meta: { title: 'Performance' },
                component: _import('performanceManagement/index'),
            },
            {
                path: 'exhibition',
                meta: { title: 'Exhibitions' },
                component: _import('customer/exhibition'),
            },
        ],
    },
    //账户信息
    {
        path: '/',
        component: HeaderLayout,
        meta: { hidden: true },
        children: [
            {
                path: 'accountSetting',
                component: AccountLayout,
                children: [
                    {
                        path: 'accountInfo',
                        component: _import('userInfo/baseInfo'),
                    },
                    {
                        path: 'changePassword',
                        component: _import('userInfo/changePassword'),
                    },
                ],
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach(async (to, from, next) => {
    //  登录不需要权限，其他路由都需要权限
    if (to.path == '/login') {
        next()
    } else {
        if (store.state.token) {
            if (!store.state.userInfo) {
                try {
                    const { data: userInfo } = await getUserInfo()
                    store.commit('SETUSERINFO', userInfo)
                } catch (error) { }
            }
            next()
        } else {
            next({ path: '/login' })
        }
    }
})
export default router
