import { createStore } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import { setTheLocalStore } from '../utils/setTheLocalStore'
import { getToken } from '../utils/cookie'
const store = createStore({
  state() {
    return {
      token: getToken() || '',
      userInfo: null,
      userData:
        setTheLocalStore.getLocalStore('userData', 'localStorage') || null,
    }
  },
  mutations,
  actions,
  getters: {
    token: (state) => state.token,
    userData: (state) => state.userInfo,
  },
})
export default store
