import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import './assets/css/normalize.less'
import store from './store'
const app = createApp(App)

app.use(router).use(store).use(Antd).mount('#app')
