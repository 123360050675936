import axios from 'axios'
import { message,Modal } from 'ant-design-vue'
import store from '@/store'
import router from '@/router'
// create an axios instance
import Qs from 'qs'
import md5 from 'js-md5'

const key = '^%$GYF'
function objKeySort(arys) {
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  var newkey = Object.keys(arys).sort()
  var newObj = {} //创建一个新的对象，用于存放排好序的键值对
  for (var i = 0; i < newkey.length; i++) {
    //遍历newkey数组
    newObj[newkey[i]] = arys[newkey[i]]
    //向新创建的对象中按照排好的顺序依次增加键值对
  }
  return newObj //返回排好序的新对象
}

// let timestamp = new Date().getTime()
// function getTime() {
//   return axios
//     .post(process.env.VUE_APP_BASEURL + '/platform/timestamp/getTimestamp')
//     .then((res) => {
//       timestamp = res.data.data
//     })
// }

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: process.env.VUE_APP_BASEURL,
  // admin
  // 192.168.5.50:9081
  // 商品
  // 192.168.5.46:9080
  // 192.168.5.50:9080
  // withCredentials: true, // send cookies when cross-domain requests
  //   timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  function (config) {
    // do something before request is sent
    // await getTime()
    if (config.method == 'get') {
      let str = ''
      // if (config.params) {
      //   config.params.timestamp = timestamp
      // } else {
      //   config.params = {}
      //   config.params.timestamp = timestamp
      // }
      if(!config.params){
        config.params={}
      }
      let par = JSON.parse(JSON.stringify(objKeySort(config.params)))
      for (let k in par) {
        if (
          typeof par[k] != 'object' &&
          typeof par[k] != 'function' &&
          typeof par[k] != 'undefined'
        ) {
          str += k + '=' + par[k] + '&'
        }
      }
      str += 'key=' + key
      let sign = md5(str)
      par.sign = sign
      config.params = JSON.parse(JSON.stringify(par))
    } else {
      let str = ''
      let par = {}
      if (!config.data) {
        config.data = {}
      }
      // 判断data类型是否经过qs转换
      if (typeof config.data === 'string') {
        // 字符串类型
        par = objKeySort({ ...Qs.parse(config.data) })
        for (let k in par) {
          if (
            typeof par[k] != 'object' &&
            typeof par[k] != 'function' &&
            typeof par[k] != 'undefined'
          ) {
            str += k + '=' + par[k] + '&'
          }
        }
        str += 'key=' + key
        let sign = md5(str)
        par.sign = sign
        config.data = Qs.stringify(par)
      } else {
        // 对象类型
        // config.data.timestamp = timestamp
        par = JSON.parse(JSON.stringify(objKeySort(config.data)))
        for (let k in par) {
          if (
            typeof par[k] != 'object' &&
            typeof par[k] != 'function' &&
            typeof par[k] != 'undefined'
          ) {
            str += k + '=' + par[k] + '&'
          }
        }
        str += 'key=' + key
        let sign = md5(str)
        par.sign = sign
        config.data = JSON.parse(JSON.stringify(par))
      }
    }
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Auth-Token'] = store.getters.token
      // config.headers['Role'] = store.getters.getRole
    }

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    let res = response.data
    // if(process.env.VUE_APP_MODE == 'production'){
    //   if(response.data.data){
    //     res.data = cryptoJS(response.data.data,response.data._0x000)
    //   }
    // }

    // if the custom code is not 20000, it is judged as an error.
    if (res.code && res.code !== 200) {
      let messageText = res.message ? res.message.split('丨') : undefined
      if (messageText) {
        message.error({
          content: messageText[0] || 'Error',
          duration: 3,
        })
      }

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 401) {
        store.dispatch('resetToken').then(() => {
          router.replace('/login')
        })
      }
      if (res.code === 500 || res.code === 400) {
        console.log(res,123)
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    // console.log(error,123)
    // Modal.confirm({
    //   content: error.config.url+ " : " +error.message,
    //   onOk() {
    //     store.dispatch('resetToken').then(() => {
    //       location.reload()
    //     })
    //   },
    //   cancelText: 'Cancel',
    //   okText:"Re-Login",
    //   onCancel() {
    //     Modal.destroyAll();
    //   },
    // });
    return Promise.reject(error)
  }
)

export default service
