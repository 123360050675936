/**
 *  该对象用于操作本地存储，localStorage、sessionStorage
 */

export const setTheLocalStore = {
  //   设置sessionStorage | localStorage
  setLocalStore(store_name, value, local_name = 'sessionStorage') {
    if (typeof value == 'object') {
      window[local_name].setItem(store_name, JSON.stringify(value))
    } else {
      window[local_name].setItem(store_name, value)
    }
  },
  // 获取sessionStorage | localStorage
  getLocalStore(store_name, local_name = 'sessionStorage') {
    try {
      return JSON.parse(window[local_name].getItem(store_name))
    } catch {
      return window[local_name].getItem(store_name)
    }
  },
  // 单个删除 localStorage | sessionStorage
  removeLocalStor(store_name, local_name = 'sessionStorage') {
    window[local_name].removeItem(store_name)
  },
  // 清空sessionStorage | localStorage
  clearLocalStore(local_name = 'sessionStorage') {
    if (local_name == 'all') {
      window.localStorage.clear()
      window.sessionStorage.clear()
    } else {
      window[local_name].clear()
    }
  },
}
