<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="less">
#App{
    height: 100%;
    line-height: 1;
}

</style>
