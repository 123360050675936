import Cookies from "js-cookie";
const partner_access_key = "partnerAccessKey";

export function setToken(token){
  return Cookies.set(partner_access_key,token,{ domain:process.env.VUE_APP_COOKIE_DOMAIN })
}

export function getToken(token){
  return Cookies.get(partner_access_key)
}

export function removeToken() {
  return Cookies.remove(partner_access_key,{ domain: process.env.VUE_APP_COOKIE_DOMAIN })
}